import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55672534"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "video-view",
  ref: "video-view"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoView = _resolveComponent("VideoView")!
  const _component_ContentWrapper = _resolveComponent("ContentWrapper")!

  return (_openBlock(), _createBlock(_component_ContentWrapper, {
    id: "video-view",
    "content-width-full": "",
    "content-body-padding-disabled": "",
    "content-header-remove-false-header-space": "",
    "content-body-bottom-margin-disabled": ""
  }, {
    "content-top": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_VideoView, _normalizeProps(_guardReactiveProps(_ctx.$attrs)), {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        }, 16)
      ], 512)
    ]),
    _: 3
  }))
}