import {Action, getModule, Module, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/lib/vuex/store";
import ConfigStore from "@/lib/vuex/modules/Config.store";
import Keycloak from "keycloak-js/lib/keycloak";
import {LoginToken} from "@/lib/models/LoginToken";
import AuthStore from "@/lib/vuex/auth.store";

@Module({dynamic: true, store, name: "keycloakStore", namespaced: true})
class KeycloakStore extends VuexModule
{
	private _keycloak: Keycloak = null;
	private _keycloakRefreshTimer: number = null;

	private readonly KEYCLOAK_TOKEN_CHECK_INTERVAL = 60000; // every 60 seconds.

	// ==========================================================================
	// Actions
	// ==========================================================================

	@Action({rawError: true})
	public async loadKeycloak(): Promise<void>
	{
		if (Object.keys(ConfigStore.configConstants).length === 0)
		{
			await ConfigStore.fetchAll();
		}

		this.setKeycloak();
	}

	@Action({rawError: true})
	public async initializeKeycloak(tokens): Promise<void>
	{
		await this._keycloak.init({
			token: tokens.accessToken,
			refreshToken: tokens.refreshToken,
			timeSkew: 0,
		});

		// setup token refresh timer
		if (this._keycloakRefreshTimer)
		{
			window.clearInterval(this._keycloakRefreshTimer);
		}
		this.setKeycloakRefreshTimer(window.setInterval(
			async () =>
			{
				const updated = await this._keycloak.updateToken(this.KEYCLOAK_TOKEN_CHECK_INTERVAL / 1000);
				if (updated)
				{
					AuthStore.setLoginToken(new LoginToken(this._keycloak.token));
				}
			},
			this.KEYCLOAK_TOKEN_CHECK_INTERVAL));
	}

	// ==========================================================================
	// Mutations
	// ==========================================================================

	@Mutation
	public setKeycloak(): void
	{
		this._keycloak = new Keycloak({
			url: ConfigStore.configConstants.fusion_keycloak_site,
			realm: ConfigStore.configConstants.fusion_keycloak_realm,
			clientId: ConfigStore.configConstants.fusion_keycloak_client_id,
		});
	}

	@Mutation
	public setKeycloakRefreshTimer(refreshTimer: number): void
	{
		this._keycloakRefreshTimer = refreshTimer;
	}

	// ==========================================================================
	// Getters
	// ==========================================================================

	get keycloak(): Keycloak
	{
		return this._keycloak;
	}
}

export default getModule(KeycloakStore);
